import { PermissionAction, PermissionSubject } from '@workspace/permissions';

import { actionNameMap, subjectNameMap } from '@/features/admin/roles/data/maps';

interface GetRestrictedMessageProps {
	action: PermissionAction;
	subject: PermissionSubject;
}

export const getRestrictedMessage = (props: GetRestrictedMessageProps) => {
	const { action, subject } = props;
	return `Необходимо добавить действие ${actionNameMap[action]} (${action}) для объекта ${subjectNameMap[subject]} (${subject}). Обратитесь к администратору.`;
};
