import { PermissionAction, PermissionSubject } from '@workspace/permissions';
import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';

import { getRestrictedMessage } from '@/utils/getRestrictedMessage';

const [username, password] = process.env.HTTP_AUTH?.split(':') ?? [];

let basicAuth = {};

if (process.env.NODE_ENV === 'development' && process.env.HTTP_AUTH) {
	basicAuth = {
		auth: { username, password },
	};
}

export const backendApi = axios.create({
	baseURL: '/backApi/',
	// NOTE: backendApi used on the client, authorized via prompt on the client

	// auth: { username, password },
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Credentials': true,
	},
});

backendApi.interceptors.request.use((config) => {
	if (typeof window === 'undefined') {
		return config;
	}

	const params = new URLSearchParams(window.location.search);

	const isInternational = window.location.pathname.startsWith('/international');
	const legalStatusId = params.get('legalStatusId');
	const isLegal = legalStatusId === '1';

	if (isInternational) {
		// eslint-disable-next-line no-param-reassign
		config.headers['x-international'] = true;
	}
	if (isLegal) {
		// eslint-disable-next-line no-param-reassign
		config.headers['x-legal'] = true;
	}

	return config;
});

backendApi.interceptors.response.use(
	(response) => response,
	(
		error: AxiosError<{
			requiredPermission?: { subject?: PermissionSubject; action?: PermissionAction; message: string };
		}>
	) => {
		if (error.response && error.response.status === 403) {
			const { requiredPermission } = error.response.data;
			if (requiredPermission?.action && requiredPermission?.subject) {
				const { subject, action, message } = requiredPermission;

				localStorage.setItem('restricted', getRestrictedMessage({ action, subject }));
				window.dispatchEvent(new Event('restricted'));
				console.error('Ошибка доступа:', message);
			}
		}
		return Promise.reject(error);
	}
);

export const backendInnerApi = axios.create({
	baseURL: process.env.INNER_BACK_HOST,
	...basicAuth,
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Credentials': true,
	},
});

export const baseApiInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = axios.CancelToken.source();
	const promise = backendApi({
		...config,
		...options,
		cancelToken: source.token,
	}).then((res) => (res?.data ?? res) as T);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
