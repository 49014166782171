import { type PermissionSubject, PermissionCondition } from '@workspace/permissions';

export const subjectNameMap: Record<PermissionSubject, string> = {
	consent: 'Согласия пользователя',
	collegial: 'Коллегиальные органы',
	criteria: 'Критерии и вес оценки проектов',
	expertise: 'Экспертиза',
	expert: 'Эксперт',
	project: 'Проект',
	selection: 'Отбор',
	import: 'Импорт',
	user: 'Профиль пользователя',
	notification: 'Уведомления',
	notification_template: 'Шаблон уведомления',
	role: 'Роль',
	project_draft: 'Черновик проекта',
	evaluation: 'Оценка первого уровня',
	roadmap: 'Дорожная карта',
	project_status_log: 'История статусов',
	project_changes_log: 'История изменения проекта',
	tracker: 'Трекер',
	head_tracker: 'Старший трекер',
	operator: 'Оператор',
	business_process: 'Бизнес-процесс',
	dictionary: 'Справочник',
	project_passport: 'Паспорт проекта',
	roadmap_template: 'Шаблон дорожной карты',
	roadmap_task: 'Задача дорожной карты',
	project_template: 'Шаблон заявки',
	report: 'Отчет',
	all: 'Все',
	certificate: 'Сертификаты пользователей',
	certificate_template: 'Шаблоны сертификатов',
	roadmap_document: 'Документы дорожной карты',
};

// TODO: use PermissionAction
export const actionNameMap: Record<string, string> = {
	read: 'Чтение',
	update: 'Обновление',
	read_list: 'Просмотр списка',
	create: 'Создание',
	delete: 'Удаление',
	cancel: 'Снятие с эксперта',
	work: 'Работа эксперта',
	change_completion_date: 'Изменение даты завершения',
	remind: 'Напоминание',
	export: 'Экспорт',
	import: 'Импорт',
	update_rights: 'Обновление прав',
	update_operator: 'Обновление оператора',
	update_tracker: 'Обновление трекера',
	quick_edit: 'Быстрое редактирование статуса',
	close: 'Закрытие',
	import_protocols: 'Импорт протоколов',
	bind_protocols: 'Привязка протоколов',
	import_files: 'Импорт файлов',
	bind_files: 'Привязка файлов',
	parse_statuses: 'Разбор статусов',
	fix_leader_ids: 'Исправление идентификаторов лидеров',
	fix_leader_and_experts: 'Исправление лидеров и экспертов',
	update_all_users_profiles: 'Обновление профилей всех пользователей',
	fix_tracker_and_admins: 'Исправление трекера и администраторов',
	import_presentations: 'Импорт презентаций',
	bind_presentations: 'Привязка презентаций',
	update_protocol: 'Обновление протокола',
	change_operator: 'Изменение оператора',
	read_list_of_dictionaries: 'Получение списка справочников',
	read_stats: 'Чтение статистики',
	update_element: 'Обновление элемента',
	create_element: 'Создание элемента',
	delete_element: 'Удаление элемента',
	update_visibility: 'Обновление видимости',
	set_ready_to_publish: 'Установка готовности к публикации',
	finish: 'Завершение',
	attach_files: 'Прикрепление файлов',
	delete_files: 'Удаление файлов',
	read_logs: 'Чтение логов',
	generate_protocol: 'Генерация протокола',
	delete_protocol: 'Удаление протокола',
	third_party_voting: 'Голосование за других участников',
	read_voting_results: 'Просмотр данных голосования',
	subscribe_to_voting_results: 'Подписка на обновления данных голосования',
	change_meeting_status: 'Смена статуса заседания',
	remove_user_data: 'Удаление данных пользователя',
	read_user_consent: 'Просмотр пользовательских согласий',
	manage: 'Полное управление',
	update_completed: 'Обновление завершенной оценки',
	download_protocol: 'Скачивание протокола',
	read_actual_list: 'Актуальный список',
	preview: 'Превью',
	create_background: 'Создание подложки',
	update_background: 'Обновление подложки',
	delete_background: 'Удаление подложки',
	mailing: 'Рассылка',
	replace: 'Замена',
	view: 'Просмотр',
	upload: 'Загрузка',
	// Roadmap documents
	// View
	view_scan: 'Просмотр ДК',
	view_consent: 'Просмотр согласия',
	view_other: 'Просмотр прочее',
	// Upload
	upload_scan: 'Загрузка ДК',
	upload_consent: 'Загрузка согласия',
	upload_other: 'Загрузка прочее',
	// Delete
	delete_scan: 'Удаление ДК',
	delete_consent: 'Удаление согласия',
	delete_other: 'Удаление прочее',
	// Replace
	replace_scan: 'Замена ДК',
	replace_consent: 'Замена согласия',
	replace_other: 'Замена прочее',
	// Update
	update_scan: 'Обновление ДК',
	update_consent: 'Обновление согласия',
	update_other: 'Обновление прочее',
};

export const actionConditionMap: Record<PermissionCondition & 'no' & 'yes', string> = {
	no: 'Нет',
	yes: 'Да',
	theme: 'Связанный с отбором',
	i_am: 'Свои',
	i_am_expert: 'Свои (эксперт)',
	i_am_tracker: 'Свои (трекер)',
	i_am_initiator: 'Свои (инициатор)',
	i_am_responsible: 'Ответственный',
	provided_rights: 'Дан доступ',
};

export const actionDescriptionMap: Record<string, string> = {
	consent_read_list: 'Раздел Настройки/Версии согласий',
	consent_create: 'Кнопка создания новой версии согласия',
	consent_remove_user_data: 'Кнопка Удалить ПДн любого пользователя для Администратора',
	consent_read_user_consent: 'Просмотр согласий всех пользователей для Администратора',
	collegial_read: 'Просмотр заседаний и вспомогательных элементов коллегиальных органов',
	collegial_read_list: 'Раздел Настройки/Коллегиальные органы',
	collegial_read_logs:
		'Просмотр вкладки История по заседанию коллегиального органа (работает совместно с Просмотром данных голосования)',
	collegial_update: 'Возможность редактирования существующего заседания коллегиального органа',
	collegial_create: 'Кнопка создания нового заседания коллегиального органа',
	collegial_delete: 'Удаление существующего заседания коллегиального органа',
	collegial_generate_protocol: 'Кнопка Сформировать протокол заседания',
	collegial_delete_protocol: 'Удаление протокола заседания',
	collegial_voting: 'Голосование по вопросам заседания для участников',
	collegial_third_party_voting: 'Возможность проголосовать за других участников',
	collegial_read_voting_results: 'Вкладка Голосование в заседании коллегиального органа',
	collegial_subscribe_to_voting_results: 'Возможность получения информации о проголосовавших без обновления страницы',
	collegial_change_meeting_status: 'Изменения статуса заседания коллегиального органа',
	criteria_read: 'Просмотр набора критериев для проведения аудита и экспертизы',
	criteria_read_list: 'Раздел Настройки/Критерии и вес оценки проектов',
	criteria_update: 'Возможность редактирования существующего набора критериев',
	criteria_create: 'Кнопка создания нового набора критериев',
	criteria_delete: 'Удаление существующего набора критериев',
	expertise_read: 'Просмотр вкладки Экспертиза проекта, Просмотр экспертиз, назначенных на эксперт',
	expertise_create: 'Кнопка Назначить эксперта',
	expertise_cancel: 'Снятие экспертизы с эксперта',
	expertise_work:
		'Кнопки Принять экспертизу в работу/Отклонить/Сохранить черновик экспертизы/Завершить оценку экспертизы',
	expertise_update: 'Редактирование существующей экспертизы',
	expertise_change_completion_date: 'Кнопка Изменить дату сдачи экспертизы',
	expertise_remind: 'Отправить напоминание об экспертизе',
	expertise_export: 'Выгрузить экспертизу',
	expert_read: 'Просмотр профиля эксперта',
	expert_read_list: 'Просмотр раздела Эксперты, Получение списка экспертов при назначении',
	expert_update: 'Редактирование профиля эксперта',
	expert_export: 'Кнопка выгрузить экспертов',
	project_read: 'Просмотр существующего проекта',
	project_read_list: 'Просмотр раздела Проекты',
	project_export: 'Возможность выгрузки проектов',
	project_import: 'Кнопка Загрузить проекты',
	project_create: 'Возможность подачи заявки, Создание заявки из черновика',
	project_update: 'Редактирование существующего проекта',
	project_update_rights: 'Кнопка Права редактирования',
	project_update_operator: 'Кнопка Редактировать оператора отбора',
	project_update_tracker: 'Кнопка Назначить/Редактировать трекера',
	project_delete: 'Отображение статуса Удален',
	project_quick_edit: 'Быстрое редактирование статуса на странице проекта',
	selection_read: 'Просмотр данных отбора',
	selection_read_list: 'Раздел Настройки/Отборы',
	selection_read_actual_list: 'Получение актуального списка отборов при создании заявки',
	selection_create: 'Кнопка создания нового отбора',
	selection_update: 'Возможность редактирования существующего отбора',
	selection_delete: 'Удаление существующего отбора',
	selection_close: 'Кнопка Завершить досрочно',
	user_read: 'Просмотр профиля конкретного пользователя',
	user_read_list: 'Просмотр раздела Пользователи, Получение списка пользователей в других объектах',
	user_update: 'Редактирование профиля пользователя',
	notification_read: 'Просмотр уведомлений',
	notification_delete: 'Удаление уведомлений',
	notification_template_read_list: 'Раздел Настройки/Шаблоны уведомлений',
	notification_template_read: 'Просмотр данных шаблона уведомления',
	notification_template_create: 'Кнопка создания нового шаблона уведомлений',
	notification_template_update: 'Возможность редактирования существующего шаблона уведомлений',
	notification_template_delete: 'Удаление существующего шаблона уведомлений',
	role_read: 'Просмотр карточки роли',
	role_read_list: 'Раздел Настройки/Роли',
	role_create: 'Кнопка создания новой роли',
	role_update: 'Возможность редактирования существующей роли',
	role_delete: 'Удаление существующей роли',
	project_draft_read: 'Просмотр раздела Черновики',
	project_draft_create: 'Возможность создания черновика заявки',
	project_draft_update: 'Редактирование черновика проекта',
	evaluation_read: 'Таб Оценка на странице проекта',
	evaluation_create: 'Кнопки Начать оценку/Завершить оценку',
	evaluation_update_completed: 'Редактирование завершенной оценки',
	evaluation_update: 'Возможность редактирования оценки первого уровня',
	roadmap_read: 'Таб дорожной карты',
	roadmap_create: 'Кнопка создания дорожной карты',
	roadmap_update: 'Возможность редактирования дорожной карты',
	roadmap_export: 'Возможность выгрузки дорожной карты',
	project_status_log_read:
		'Доступен таб Статус в карточке проекта (не работает без Проект/Просмотр списка и Проект/Чтение)',
	project_status_log_download_protocol:
		'Доступно скачивание протокола в табе Статус (не работает без История статусов/Чтение)',
	project_status_log_update_protocol: 'Доступно обновление протокола в табе Статус (История статусов/Чтение)',
	project_changes_log_read:
		'Доступен таб История в карточке проекта (не работает без Проект/Просмотр списка и Проект/Чтение)',
	project_changes_log_export: 'Доступен экспорт в табе История (не работает без История изменения проекта/Чтение)',
	tracker_read: 'Назначение/смена трекера из выпадающего списка в модальном окне',
	head_tracker_create: 'Создание или обновление профиля пользователя с ролью headTracker при авторизации',
	head_tracker_change_operator: 'Кнопка редактирования оператора в профиле старшего трекера',
	operator_read:
		'Просмотр оператора отбора в карточке проекта, Просмотр списка операторов отбора при назначении оператора',
	business_process_read: 'Просмотр данных бизнес-процесса проекта',
	business_process_read_list: 'Раздел Настройки/Бизнес-процессы',
	business_process_create: 'Кнопка создания нового бизнес-процесса',
	business_process_update: 'Возможность редактирования существующего бизнес-процесса',
	business_process_delete: 'Удаление существующего бизнес-процесса',
	dictionary_read: 'Просмотр элемента справочника',
	dictionary_read_list: 'Страница элементов справочника',
	dictionary_read_list_of_dictionaries: 'Раздел Настройки/Справочники',
	dictionary_read_stats: 'Просмотр статистики по справочникам',
	dictionary_update_element: 'Кнопка создания нового элемента справочника',
	dictionary_create_element: 'Возможность редактирования существующего элемента справочника',
	dictionary_delete_element: 'Удаление существующего элемента справочника',
	project_passport_update: 'Возможность редактирования паспорта проекта',
	project_passport_create: 'Кнопка создания нового паспорта проекта',
	project_passport_export: 'Возможность выгрузки паспорта проекта',
	project_passport_read:
		'Таб Паспорт проекта на странице конкретного проекта, Таб Паспорт проекта на главной странице',
	project_passport_update_visibility: 'Отключение/подключение видимости полей паспорта на витрине',
	project_passport_set_ready_to_publish: 'Кнопка Паспорт готов к публикации на витрине проектов',
	roadmap_template_read: 'Просмотр данных шаблона дорожной карты',
	roadmap_template_read_list: 'Раздел Настройки/Дорожные карты',
	roadmap_template_create: 'Кнопка создания нового шаблона дорожной карты',
	roadmap_template_update: 'Возможность редактирования существующего шаблона дорожной карты',
	roadmap_template_delete: 'Удаление существующего шаблона дорожной карты',
	roadmap_task_read: 'Просмотр задач дорожной карты',
	roadmap_task_create: 'Кнопка создания задачи дорожной карты',
	roadmap_task_update: 'Возможность редактирования существующей задачи дорожной карты',
	roadmap_task_delete: 'Удаление существующей задачи дорожной карты',
	roadmap_task_finish: 'Завершение задачи дорожной карты',
	roadmap_task_attach_files: 'Прикрепление файлов к задаче дорожной карты',
	roadmap_task_delete_files: 'Удаление файлов из задачи дорожной карты',
	project_template_read: 'Просмотр данных шаблона заявки',
	project_template_read_list: 'Раздел Настройки/Шаблоны заявки',
	project_template_create: 'Кнопка создания нового шаблона заявки',
	project_template_update: 'Возможность редактирования существующего шаблона заявки',
	project_template_delete: 'Удаление существующего шаблона заявки',
	report_read: 'Просмотр вкладки Отчёты',
	report_export: 'Выгрузка Отчётов',
	certificate_read: 'Просмотр сертификатов пользователей в личном кабинете',
	certificate_update: 'Возможность редактирования сертификатов пользователей в личном кабинете',
	certificate_preview: 'Возможность предпросмотра сертификатов пользователей в личном кабинете',
	certificate_template_read: 'Просмотр шаблонов сертификатов и вспомогательных элементов',
	certificate_template_read_list: 'Раздел Настройки/Шаблоны сертификатов',
	certificate_template_create: 'Возможность создания шаблонов сертификатов',
	certificate_template_create_background: 'Возможность создания подложки сертификата',
	certificate_template_update_background: 'Возможность редактирования названия подложки',
	certificate_template_update: 'Возможность редактирования шаблона сертификата',
	certificate_template_delete: 'Возможность удаления шаблона сертификата',
	certificate_template_delete_background: 'Возможность удаления подложки',
	certificate_template_preview: 'Возможность предварительного просмотра сертификата',
	certificate_template_mailing: 'Возможность отправлять сертификаты пользователям',
	// Roadmap documents
	// View
	roadmap_document_view_scan: 'Отображение вкладки "ДК"',
	roadmap_document_view_consent: 'Отображение вкладки "Согласия"',
	roadmap_document_view_other: 'Отображение вкладки "Прочее"',
	// Upload
	roadmap_document_upload_scan: 'Загрузка документа вкладки "ДК"',
	roadmap_document_upload_consent: 'Загрузка документа вкладки "Согласия"',
	roadmap_document_upload_other: 'Загрузка документа вкладки "Прочее"',
	// Delete
	roadmap_document_delete_scan: 'Удаление документа вкладки "ДК"',
	roadmap_document_delete_consent: 'Удаление документа вкладки "Согласия"',
	roadmap_document_delete_other: 'Удаление документа вкладки "Прочее"',
	// Replace
	roadmap_document_replace_scan: 'Замена документа вкладки "ДК"',
	roadmap_document_replace_consent: 'Замена документа вкладки "Согласия"',
	roadmap_document_replace_other: 'Замена документа вкладки "Прочее"',
	// Update
	roadmap_document_update_scan: 'Обновление описания документа вкладки "ДК"',
	roadmap_document_update_consent: 'Обновление описания документа вкладки "Согласия"',
	roadmap_document_update_other: 'Обновление описания документа вкладки "Прочее"',

	roadmap_document_replace: 'Замена документа',
	roadmap_document_delete: 'Удаление документа',
	roadmap_document_upload: 'Загрузка документа',
	roadmap_document_update: 'Обновление описания документа',
};
