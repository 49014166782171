/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PureAbility, RawRuleOf } from '@casl/ability';
import { Ability, AppAbility, PermissionAction, PermissionCondition, PermissionSubject } from '@workspace/permissions';

import { PermissionElement, permissionElements } from '@/features/permissions/elements';
import { TProjectDetailResponse } from '@/types/project-detail';

export type RolePermissionElement = {
	subject: PermissionSubject;
	action: PermissionAction;
	condition: PermissionCondition;
};

export class Permission {
	private static _instance: Permission;

	private _ready = false;

	public get ready() {
		return this._ready;
	}

	public static get instance(): Permission {
		this._instance = this._instance || new Permission();
		return this._instance;
	}

	public canAccess(element: PermissionElement): boolean {
		if (!this.ready) {
			return true;
		}
		if (!this.ability) {
			// eslint-disable-next-line no-console
			console.error('Ability not available, check this issue');
			return false;
		}
		// @ts-ignore
		if (this.ability.can('manage', 'all')) {
			return true;
		}
		const [action, subject] = permissionElements[element];
		// @ts-ignore
		return this.ability.can(action, subject);
	}

	public getCondition(element: PermissionElement) {
		const [action, subject] = permissionElements[element];

		// @ts-ignore
		const rules = this.ability.rulesFor(action, subject);
		if (!rules.length) {
			return false;
		}
		return rules[0]?.conditions as PermissionCondition;
	}

	public canAccessWithConditionByProject({
		element,
		userId,
		allowedThemesIds,
		project,
	}: {
		element: PermissionElement;
		userId: number;
		allowedThemesIds: number[];
		project?: TProjectDetailResponse;
	}) {
		const [isAbleToView, condition] = this.canAccessAndFindCondition(element);

		if (!isAbleToView) return false;
		if (!condition) return true;
		if (project) {
			if (condition === 'i_am_initiator' && project.leader?.userId === userId && project.initiator?.id === userId)
				return true;
			if (condition === 'i_am_tracker' && project.tracker?.userId === userId) return true;
			if (condition === 'theme') return allowedThemesIds.includes(project.themeId ?? 0);
		}

		return false;
	}

	public canAccessAndFindCondition(element: PermissionElement) {
		if (!this.ready) {
			return [true];
		}
		return [this.canAccess(element), this.getCondition(element)];
	}

	public canNotAccess(element: PermissionElement) {
		return !this.canAccess(element);
	}

	public canAccessSome(elements: PermissionElement[]): boolean {
		return elements.some((el) => this.canAccess(el));
	}

	public canAccessAll(elements: PermissionElement[]): boolean {
		return !elements.some((el) => this.canNotAccess(el));
	}

	public initAbility(permissions: RawRuleOf<AppAbility>[]) {
		this.ability = new PureAbility<Ability, string>(permissions, {
			conditionsMatcher: () => null!,
		});
		this._ready = true;
	}

	static elementInfo(element: PermissionElement) {
		return permissionElements[element] as [PermissionAction, PermissionSubject];
	}

	private ability: PureAbility<Ability, string>;
}
